<script lang="ts">
  // Turn off inheritance through attributes
  export default {
    inheritAttrs: false
  };
</script>

<script setup lang="ts">

  import type { FormWidgetData } from "../../../widgetDataTs.js";
  import type { ContactFormSettingsKey } from "../../form-settings/form-experience/form-settings-keys.js";
  import SimpleContactForm from "../form-element/simple-contact-form.vue";
  import { useFormServices } from "../../composables/use-form-services.js";
  import { computed, watch } from "vue";
  import { TranslationNamespaceEnum } from "../../locale/settings/translation-namespaces.js";
  import { useTranslation } from "i18next-vue";
  import { ContactFormPostKeysEnum } from "../../locale/translation-keys/all-keys.js";

  defineProps<{
    formSettingsKey: ContactFormSettingsKey;
    widgetData: FormWidgetData<"ContactForm">;
    customData: Record<string, any>;
  }>();
  const { FormState } = useFormServices({ callerLabel: "partnerForm" });

  const showUnlockedView = computed(() => {
    return FormState.value === "RegularFormComplete";
  });

  const { t } = useTranslation();
  
  watch(showUnlockedView, (newValue) => {
    if(newValue)
    {
      $('.outside-heading').hide(); 
    }
  });
</script>

<template>
  <template v-if="!showUnlockedView">
    <SimpleContactForm :form-settings-key="formSettingsKey" :widget-data="widgetData" class="single-row" :custom-data="customData" /> 
  </template>
  <template v-else>
    <!--thank you message-->
    <p class="text-center">
      <img class="email" src="https://static.mastercontrol.com/assets/persist/images/mcui-resources-sent.png" alt="email sent">
    </p>
    <h2>{{ t(ContactFormPostKeysEnum.partnerMessage, {ns: TranslationNamespaceEnum.contactPostSubmit} ) }}</h2>
  </template>
</template>
